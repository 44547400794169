<template>
  <div>
    <div class="success">
      <div class="success-box">
        <div class="pay-success">支付成功</div>
        <el-button type="primary" round @click="this.$router.push('/passportInfo')">返回首页</el-button>
      </div>
    </div>
 </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {}
  },
  props: {},
  components: {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang='less'>
.success {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.success-box {
  width: 400px;
  height: 300px;
  // background-color: pink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pay-success {
  font-size: 40px;
  margin-bottom: 40px;
}
</style>
